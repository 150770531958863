import React, { useState } from 'react';
import './Registration.css'; // Assuming you have a CSS file named Registration.css for styling
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { app } from './Firebase';
import img from './GANGAMATTILOGO.png'
const auth = getAuth(app);

const Registration = () => {
  const defaultPassword = 'GangaMatti';
  const navigate = useNavigate(); // Initialize useNavigate hook
  const [isRegistered, setIsRegistered] = useState(false); // State to track registration status

  const [userToken, setUserToken] = useState(null); 
  const handleRegistration = async (e) => {
    e.preventDefault();
    
    const fullName = e.target.fullName.value;
    const email = e.target.email.value;
    const password = e.target.password.value || defaultPassword; 
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      console.log('User registered:', userCredential.user);
      setIsRegistered(true);
      
      // Get user token after registration
      const idToken = await userCredential.user.getIdToken();
      setUserToken(idToken);

      navigate('/Login', { state: { token: idToken } }); // Pass token to Login component
    } catch (error) {
      console.error('Registration error:', error.message);
    }
  };


  return (
    <div div className='wholepage'>
    <div className='Registration' >
        {/* <img src={img} alt="Logo" className="logo" /> */}
      <div className='mainpage-registration'>
        <h2>Join Ganga Matti</h2>
        <div className="card-container">
          <div className="card">
            <div className="card-content left-content">
              <img src={img} alt="Registration Image" />
            </div>
            <div className="card-content right-content">
            <form onSubmit={handleRegistration}>
  <div className="form-group">
    <label htmlFor="fullName">Full Name</label>
    <input type="text" id="fullName" name="fullName" placeholder="Enter your full name" />
  </div>
  <div className="form-group">
    <label htmlFor="email">Email</label>
    <input type="email" id="email" name="email" placeholder="Enter your email" />
  </div>
  <div className="form-group">
    <label htmlFor="password">Password</label>
    <input type="password" id="password"  defaultValue={defaultPassword}  name="password" placeholder="Enter your password" />
  </div>
  <div className="form-group">
    <label htmlFor="gender">Gender</label>
    <select id="gender" name="gender">
      <option value="male">Male</option>
      <option value="female">Female</option>
      <option value="other">Other</option>
    </select>
  </div>
  <div className="form-group">
    <label htmlFor="phone">Phone Number</label>
    <input type="text" id="phone" name="phone" placeholder="Enter your phone number" />
  </div>
  <div className="form-group">
    <label htmlFor="messsage">Message</label>
    <input type="text" id="message" name="message" placeholder="Enter the message" />
  </div>
  <div className="form-group">
    <label htmlFor="address">Address</label>
    
    <textarea id="address" name="address" placeholder="Enter your address"></textarea>
    <button type="submit">Register</button>  
  </div>
 
</form>

              <p>Already registered? <Link to="/Login">Login</Link></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Registration;
