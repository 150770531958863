import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './Login/Login';
import Registration from './Login/Registration/Registration';
import Home from './Login/Registration/Home';
import './App.css'

const App = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Registration />}/>
        <Route path="/Login" element={<Login/>} />
        <Route path="/register" element={<Registration />} />
        <Route path="/Home" element={<Home/>} />

      </Routes>
    </Router>
  );
};

export default App;
