import React, { useState } from 'react';
import './Login.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { app } from './Registration/Firebase';

const auth = getAuth(app);

const Login = () => {
  const location = useLocation();
  const token = location.state?.token;
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(null); // Clear previous error messages

    
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const userToken = await userCredential.user.getIdToken();
      console.log('User logged in:', userCredential.user);
      navigate('/Home');
    } catch (error) {
      setError(error.message); // Set error message if login fails
      console.error('Login error:', error.message);
    }
  };

  return (
    <div className='Login'>
      <div className='mainpage-login'>
        <h2>Login</h2>
        <form onSubmit={handleLogin}>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input type="email" id="email" value={email}  onChange={(e) => setEmail(e.target.value)}  name="email" placeholder="Enter your email" />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input type="password" id="password" value={password}  onChange={(e) => setPassword(e.target.value)}  name="password" placeholder="Enter your password" />
            {error && <div className="error-message">{error}</div>}
            <button type="submit">Login</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
