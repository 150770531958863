// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAjM5SKCIk89DJTdjhkQ_NYuMj2h24ev9c",
  authDomain: "registration-d4040.firebaseapp.com",
  projectId: "registration-d4040",
  storageBucket: "registration-d4040.appspot.com",
  messagingSenderId: "440944382327",
  appId: "1:440944382327:web:699664927b019f548b9172",
  measurementId: "G-3LSS3JW7PM"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { app, analytics };